.App {
  margin-left:15%;
  margin-right:15%;
  /*background: #ffffff;*/
  height: 100%;
}

#root {
  height: 100%;
}

.maincontent {
  /*min-height: 100%;*/
  height: auto !important;
  height: 100%;
  min-height: 100%;
  background: #ffffffef;
  margin-top: 2px;
  margin-bottom: 0px;
}

.App-logo {
  animation: thunder ease-out 7s infinite;
  pointer-events: none;
  border-radius: 50%;
  height: 140px;
  left: 30px;
  z-index:-1;
  top:-20px;
  position: relative;
  -webkit-filter: drop-shadow(3px 3px 3px #777);
  filter: drop-shadow(3px 3px 3px #777);
}

.App-header {
  /*background-color: #000;*/
  min-height: 1vh;
  height: 100px;
  display: flex;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  padding:5px;
}

.App-Member-Select {
  width: 300px;
  margin-top:20px;
  margin-right:5px;
}

.content {
  padding:32px;
}

@keyframes thunder {
  from { filter: invert(0%) drop-shadow(3px 3px 3px #777); }
  92% {filter: invert(0%) drop-shadow(3px 3px 3px #777); }
  93% { filter: brightness(10%) invert(80%) drop-shadow(3px 3px 3px #eee); }
  94% { filter: brightness(10%) invert(50%) drop-shadow(3px 3px 5px #aaa); }
  96% { filter: brightness(10%) invert(90%) drop-shadow(3px 3px 3px #fff); }
  to { filter: invert(0%) drop-shadow(3px 3px 3px #777); }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #ffffffef;
  margin:0px;
}

.tab a {
  font-size: 1em;
  display:block;
  padding: 14px 16px;
}

.tab li {
  display:block;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  color: black;
}

/* Change background color of buttons on hover */
.tab li:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab li.active {
  background-color: #ccc;
}
